<template>
  <div>
    <van-popup
      v-model:show="isShowMailDetail"
      position="right"
      :style="{ width: '100%', height: '100%' }"
      @close="closeMailDetail"
      >
      <div class="next mailDetail">
        <div class="title">
          <span>{{ $t("my.mail.msgDetail") }}</span>
          <div class="fixed" @click="closeMailDetail">
            <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.35rem" height="0.35rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
          </div>

          <div class="title_ellipsis" v-show="betPramas.titleType != 1" @click="deleteItem">
            {{ $t("my.mail.delete") }}
          </div>
        </div>

        <div class="mailDetailSutitel flexCenter">
          <div class="userAvatarBox">
            <img v-show="betPramas.titleType == 1" src="../../assets/img/my/mail/backstage.png">
            <img v-show="betPramas.titleType == 2" src="../../assets/img/my/mail/automatic.png">
          </div>

          <div style="width: 100%;text-align: left;">
            <div class="newsItemTitle">{{ mailDetailTitle }}</div>
            <div class="time">{{ betPramas.sendTime ? getTime(betPramas.sendTime) : "--" }}</div>
          </div>
        </div>
        <div class="mailDetailCont">
          {{ betPramas.content }}
        </div>
      </div>
    </van-popup>
  </div>
  </template>
  
  <script>
  import moment from "moment";
  import { mapState } from 'vuex';
  export default {
    name: "mailDetail",
    props: ['showMailDetail', 'detailData'],
    data() {
      return {
        type: 1,
        isShowMailDetail: false
      }
    },
    computed: {
      ...mapState(['systemLetterLists', 'betPramas']),
      mailDetailTitle() {
        let str = "";
        if (this.betPramas.titleType == 1) {
          str = `【QQlive - ${ this.$t('my.mail.title') }】`;
        }

        if (this.betPramas.titleType == 2) {
          str = this.$t('my.mail.systemNotice');
        }

        return str;
      }
    },
    created() {
      let that = this;
      setTimeout(()=> {
        console.log(555);
        
        that.isShowMailDetail = true;
      }, 10);
    },
    methods: {
      goOtherPage() {
        this.$router.push('/mine/mail');
      },
      getTime(val) {
        if (val) {
          return moment(val).format('HH:mm DD-MM-YYYY');
        }

        return "";
      },
      deleteItem() {
        this.$dialog.confirm({
          message: `${this.$t('my.mail.deleteTip')}`,
          showCancelButton: true,
          confirmButtonText: `${this.$t('common.confirm')}`,
          cancelButtonText:  `${this.$t('common.cancel')}`,
          closeOnClickOverlay: true,
          className: "confirmDialog",
          confirmButtonColor: "#eb457e"
          })
          .then(() => {
            this.$emit('changeSingleLetterStatus', this.betPramas.mailIndex, 2 )
          })
          .catch(() => {
              // on cancel
          });
      },
      closeMailDetail() {
        this.isShowMailDetail = false;
        let that = this;
        setTimeout(()=> {
            that.$emit('closeMailDetail')
        }, 1000)
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .mailDetail {
    height: 100vh;
    background-color: #fff;

    &.next {
        padding-top: 0.88rem;

        .title {
          box-shadow: 0 0.01rem 0.08rem 0 rgba(0, 0, 0, 0.05);
        }
    }

    .mailDetailSutitel {
      background-color: #fff;
      margin: 0.34rem auto 0.3rem;
      padding: 0 0.24rem 0.3rem 0.24rem;
      border-bottom: 1px solid #E5E5E5;

      .userAvatarBox {
        width: 1.02rem;
        height: 1.02rem;
        margin-right: 0.2rem;
        position: relative;

        img {
          width: 1.02rem;
          height: 1.02rem;
          border-radius: 50%;
        }               
      }

      .newsItemTitle {
        font-size: 0.28rem;
        margin-bottom: 0.22rem;
      }

      .time {
        font-size: 0.24rem;
        color: #969696;
        padding-right: 0.2rem;
      }
    }

    .mailDetailCont {
      text-align: left;
      padding: 0 0.24rem;
      color: #666;
      font-size: 0.24rem;
    }
  }
  </style>