<template>
    <div>
        <van-popup v-model:show="isShowGiftList" round class="gameListDialog" :safe-area-inset-bottom="true"
            position="bottom" @close="closeGiftList">
            <div class="giftListDialogCont">
                <van-swipe :indicator-color="'#fff'" :loop="false" class="giftListSwipe">
                    <van-swipe-item v-for="(item, index) in showList" :key="`${index}-liveRoom-giftList`">
                        <div v-for="(xitem, xindex) in item" :key="`${xindex}-${index}-liveRoom-giftList`"
                            class="giftListItem" :class="{ 'active': giftActive.gid == xitem.gid }" @click="selectGift(xitem)">
                            <div v-if="xitem.tagsArr.length > 0" class="giftType">
                                <p v-for="(sitem, sindex) in xitem.tagsArr" :key="`${sindex}- ${xindex}-${index}-giftList-liveRoom`">
                                    {{ sitem }}
                                </p>
                            </div>
                            <img class="gift" v-real-img="xitem.cover"
                                :errorimg="require(`../../assets/img/errorImg/2.png`)" />
                            <div class="giftListName">{{ xitem.gname }}</div>
                            <div class="flexCenterBL amount">
                                <img src="../../assets/img/gold.png" />
                                {{ xitem.goldCoin }}
                            </div>
                        </div>
                    </van-swipe-item>
                </van-swipe>

                <div class="flexBetween dialogFotter">
                    <div class="flexCenter">
                        <div class="myselfAmount flexCenter">
                            <img src="../../assets/img/gold.png" />
                            {{ member && member.goldCoin ? numFormat(member.goldCoin) : '0'}}
                        </div>
                        <div @click="goRechangeComfirm" class="rechageBtn">{{ $t('my.menuList.recharge') }}</div>
                    </div>

                    <div v-show="giftActive.playType || !giftActive.gid" @click="send" class="sureBtn">{{ $t('common.confirm') }}</div>
                    <div v-show="!giftActive.playType && giftActive.gid">
                        <van-popover v-model:show="showNum" placement="top-end">
                            <div class="popverBox">
                                <div v-for="(item, index) in giftNumArr" :key="`${index}-liveRoom-giftList-item`" @click="itemClick(item)">
                                    <span>{{ item.num }}</span> {{ item.name }}
                                </div>
                            </div>
                            <template #reference>
                                <div class="giftNumBtnItem giftNumBtn">{{ num }}</div>
                            </template>
                        </van-popover>
                        <div @click="send" class="giftNumBtnItem giftConfirmBtn">{{ $t('common.confirm') }}</div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>
  
<script>
import { mapState } from 'vuex';
import { configProp } from "@/api/config";
import { sendGift } from "@/api/live";
import rechangeEntry from '../mixin/rechangeEntry';
export default {
    name: "liveRoomGiftList",
    mixins: [rechangeEntry],
    data() {
        return {
            isShowGiftList: false,
            giftActive: {}, //礼物列表选中，带改成Id
            giftList: [], // 
            showNum: false,
            num: 1,
            giftNumArr: [
                { num: 1314, name: "Một đời một kiếp"},
                { num: 520, name: "Anh yêu em"},
                { num: 360, name: "Anh nhớ em"},
                { num: 99, name: "Yêu em dài lâu"},
                { num: 30, name: "Có phúc ba đời"},
                { num: 10, name: "êu em mãi mãi"},
                { num: 1, name: "Một lòng một ý"},
            ],  
            tags: {  //礼物对应枚举
                1: "Vàng",
                2: "Bạch kim",
                3: "Kim cương",
                4: "Đại sư",
                5: "Vua",
                6: "Hào khí",
                7: "Trứng rung",
                8: "Đề Xuất"
            },
        }
    },
    computed: {
        ...mapState(['betPramas', 'member', 'liveInfo']),
        showList() {
            let arr = [];
            if (this.giftList.length > 0) {
                arr = this.getNewArray(JSON.parse(JSON.stringify(this.giftList)), 8);
            }

            return arr;
        }
    },
    created() {
        let that = this;
        setTimeout(() => {
            that.isShowGiftList = true;
        }, 10)

        this.configProp();
    },
    methods: {
        itemClick(item) {
            this.num = item.num;
            this.showNum = false;
        },
        // 送礼
        send() {
            let parmas = {
                anchorId: this.liveInfo.anchorId,
                combo: this.num, //连击数
                count: 1, // 礼物数
                gid: this.giftActive.gid,
                liveId: this.liveInfo.liveId
            }
            sendGift(parmas).then(res => {
                if(res.code === 0 || res.code === '0') {
                    // console.log(res);
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                console.log(res);
            }).catch(err => {
                console.log(err);
            });
        },
        // 选中礼物
        selectGift(item) {
            this.giftActive = JSON.parse(JSON.stringify(item));
            this.num = 1;
        },
        // 数组拆分
        getNewArray(array, subGroupLength) {
            let index = 0;
            let newArray = [];
            while (index < array.length) {
                newArray.push(array.slice(index, index += subGroupLength));
            }
            return newArray;
        },
        // 关闭
        closeGiftList() {
            this.isShowGiftList = false;
            let that = this;
            setTimeout(() => {
                that.$emit('closeGiftList')
            }, 1000);
        },
        // 座驾列表
        configProp() {
            configProp(this.member.uid).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let arr = res.data.filter((item) => {
                        let tagArr = item.tags.split(','), newTags = [];
                        tagArr.forEach(xitem => {
                            if (this.tags[xitem]) {
                                newTags.push(this.tags[xitem])
                            }
                        });
                        item.tagsArr = newTags;
                        return item.type === 0 && item.isShow
                    });

                    this.giftList = arr.sort(function (a, b) {
                        return (a.sort - b.sort);
                    });
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
    }
}
</script>
  
<style lang="scss" scoped>
.giftListDialogCont {
    .giftListSwipe {
        font-size: 0.24rem;
        text-align: left;
        padding: 0.2rem 0.28rem;

        .giftListItem {
            width: calc(25% - 0.14rem);
            border: 1px solid transparent;
            border-radius: 0.3rem;
            margin-bottom: 0.2rem;
            display: inline-block;
            text-align: center;
            position: relative;

            &.active {
                border: 1px solid #eb457e;
            }

            .gift {
                width: 1.14rem;
                max-height: 1.5rem;
                margin-bottom: 0.08rem;
            }

            .amount {
                font-size: 0.2rem;
                margin: 0.06rem 0 0.12rem;

                img {
                    width: 0.24rem;
                    height: 0.24rem;
                    margin-right: 0.05rem;
                }
            }

            .giftListName {
                height: 0.6rem;
                padding: 0 0.05rem;
            }

            .giftType {
                position: absolute;
                top: 0;
                right: 0;
                display: inline-block;
                color: #000;
                font-size: 0.2rem;

                p {
                    background-color: rgba(247, 249, 254, 0.5);
                    border-radius: 0.4rem;
                    padding: 0rem 0.1rem;
                    margin-bottom: 0.05rem;
                }
            }
        }
    }
}

.dialogFotter {
    padding: 0 0.28rem;
    margin-bottom: 0.2rem;

    .myselfAmount {
        margin-right: 0.2rem;
        color: #ffd96b;
        font-size: 0.4rem;

        img {
            width: 0.28rem;
            height: 0.28rem;
            margin-right: 0.1rem;
        }
    }

    .rechageBtn {
        font-size: 0.24rem;
        color: #fff;
        border: 1px solid #ffd96b;
        padding: 0.1rem 0.3rem 0.12rem;
        border-radius: 0.4rem;
    }

    .sureBtn {
        background: -webkit-linear-gradient(left,#eb538e,#e14077);
        background: linear-gradient(90deg, #eb538e,#e14077);
        padding: 0.1rem 0.4rem 0.15rem;
        border-radius: 0.5rem;
    }

    .giftNumBtnItem {
        display: inline-block;
        padding: 0.1rem 0.2rem;

        &.giftNumBtn {
            border-top-left-radius: 0.4rem;
            border-bottom-left-radius: 0.4rem;
            border: 1px solid #eb538e;
        }

        &.giftConfirmBtn {
            background-color: #eb538e;
            border-top-right-radius: 0.4rem;
            border-bottom-right-radius: 0.4rem;
        }
    }
}

</style>
<style lang="scss">
.popverBox {
    font-size: 0.28rem;
    padding: 0.2rem 0.2rem 0.2rem 0;

    div {
        padding: 0.1rem 0.2rem;

        span {
            display: inline-block;
            width: 1rem;
            text-align: center;
            color: #ffd96b;
        }
    }
}
</style>