import axios from 'axios';
import md5 from 'js-md5';
// import jwt_decode from "jwt-decode";
import { Toast } from "vant";
import store from "../store/index";
import { getLanguage, guid } from "@/utils/index";
import router from "../router";
import Aes from "@/utils/aes.js";

const apiBaseUrl = process.env.NODE_ENV === 'production' ? 'https://gateway.qqlive.online' : '/commonApi';

const api = axios.create({
  baseURL: apiBaseUrl,
  timeout: 50000,
  withCredentials: true,
});

api.interceptors.request.use(
  (config) => {
    const timer = new Date().getTime();
    const uid = localStorage.getItem('uid') || guid();
    const sign = md5(`${uid}jgyh,kasd${timer}`);
    
    config.headers['OS'] = '0';
    config.headers['X-Language'] = getLanguage(store.state.supportLanguage);
    config.headers['Accept-Language'] = getLanguage(store.state.supportLanguage);
    config.headers['X-AppVersion'] = '2.5.0';
    config.headers['P-G'] = 'N';
    config.headers['appId'] = 'QQlive';
    config.headers['X-UDID'] =  uid;
    config.headers['X-Timestamp'] = timer;
    config.headers['X-Sign'] = sign;
    config.headers['Content-type'] = 'application/json;charset=UTF-8';
    config.headers['N-L'] = 'Y';
    
    // config.headers['P-AE'] = `1`; 5eb0f9
    if (sessionStorage.getItem('token')) {
      config.headers['Authorization'] = `HSBox ${sessionStorage.getItem('token')}`;
    }

    if (config.url.indexOf('/live-client/live/inter/room/220') != -1) {
      config.headers['NEW-PK'] = 1;
    }

    if (config.headers['P-AE'] === '1' && config.method === 'post') {
      config.data = Aes.encryptLast(config.data);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    const code = response.status;
    if (String(code) !== '200') {
      return Promise.reject('error');
    } else {
      if (response.config.headers['P-AE'] === '1' && response.config.method === 'post') {
        response.data = Aes.decryptLast(response.data);
      }
      return response.data;
    }
  },
  (error) => {
    const code = (error.response.data && error.response.data.code) || 0;
    const msg = (error.response.data && (error.response.data.msg || error.response.data.desc)) || '';

    if (code == 991 || code == 992 || code == 993 || code == 1040 || code == 424) {
      router.replace('/login');
      store.dispatch("loginOutTm");
    }

    if (code == 1040 || code == 424) {
      if(msg) store.commit('setState', { loginOutInfo: msg, isLoginOutIm: true });
    } else {
      if (msg) {
        Toast(msg);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
