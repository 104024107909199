<template>
  <van-pull-refresh v-model="isLoadMember" :disabled="isRefreshDisable" @refresh="onRefresh">
    <template #pulling>
      <van-loading />
    </template>

    <!-- 释放提示 -->
    <template #loosing>
      <van-loading />
    </template>

    <!-- 加载提示 -->
    <template #loading>
      <van-loading />
    </template>
    <div class="my" :style="{ height: myBoxHeight }">
      <div class="header">
        <div class="headR">
          <div class="notice">
            <img @click="goOtherPage('/mine/mail')" src="../../assets/img/my/privateLetter.png" />
            <span v-show="isUnreadMsgFlag"></span>
          </div>
        </div>
      </div>
      <div class="myTop">
        <div class="avatarBox">
          <img v-if="member && member.avatar" @click="goOtherPage('/mine/userInfo')" class="avatar" :src="member.avatar" />
          <img v-else @click="goOtherPage('/mine/userInfo')" class="avatar" src="../../assets/img/errorImg/2.png" />
          <img @click="goOtherPage('/mine/userInfo/edit')" class="edit" src="../../assets/img/my/edit.png" />
        </div>

        <div class="userIdBox flexCenterBL">
          <div>{{ member.nickname }}</div>
          <div v-if="member && member.userLevel" class="level">
            <img v-real-img="getImg(member.userLevel)" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>
            <span :class="{'small':member.userLevel<10}">{{ member.userLevel ? member.userLevel : 0 }}</span>
          </div>

          <img v-if="member && member.sex == 2" src="../../assets/img/my/icon/man.png" />
          <img v-else src="../../assets/img/my/icon/woman.png" />

          <div v-if="member && member.badgeShowList && member.badgeShowList.length > 0 && member.badgeShowList.indexOf(2) != -1" class="nobleLevel">
            <img v-real-img="getImg(member.anchorLevel)" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>
            <span :class="{'small':member.anchorLevel<10}">{{member.anchorLevel}}</span>
          </div>

          <div class="userIcon" v-if="member && member.badgeShowList && member.badgeShowList.length > 0">
            <img v-for="(item, index) in member.badgeShowList" :key="`${index}--my-userInfo`" v-real-img="item.logoUrl" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
          </div>
        </div>

        <div class="userIdBox">
          ID: {{ member.uid }}

          <span @click="copy(member.uid)" class="copy">{{ $t('common.copy') }}</span>
        </div>
      </div>

      <div v-for="(xitem, xindex) in tabList" :key="`${xindex}-tabList-mine`">
        <div v-if="xitem.code == 'myOne'" class="flexCenterAroundBL interactive">
          <div v-for="(item, index) in xitem.systemConfigTagListArr" :key="`${index}-newFans`" @click="goOtherPage(item.link)">
            <p>{{ member[item.key] }}</p>
            <p>{{ item.tagNameShow }}</p>
          </div>
        </div>

        <div v-if="xitem.code == 'myTwo'" @click="goWallet(xitem)" class="centerWallet flexBetween">
          <div>
            <p class="total">{{ member && member.goldCoin ? numFormat(member.goldCoin) : '0' }}</p>
            <p>{{ getString(xitem, 'balanceLink') }}</p>
          </div>
          <div v-show="getPermissions(xitem, 'recovery')" @click.stop="recycle" class="recycle">{{ getString(xitem, 'recovery') }}</div>
        </div>

        <div v-if="xitem.code == 'myThree' && xitem.systemConfigTagListArr && xitem.systemConfigTagListArr.length > 0" class="menuBox">
          <ul>
            <li v-for="(item, index) in xitem.systemConfigTagListArr" :key="`${index}--menuList`" v-show="item.status" @click="goOtherPage(item.link)">
              <img v-real-img="item.iconUrl" />
              <p>{{ item.tagNameShow }}</p>
            </li>
          </ul>
        </div>

        <div v-if="xitem.code == 'myFour'">
          <img v-for="(item, index) in xitem.systemConfigTagList" :key="`${index}-my-invite`" class="inviteBg" v-show="xitem.status" @click="$router.push('/mine/invite')" v-real-img="item.iconUrl" />
        </div>

        <ul v-if="xitem.code == 'myFive'" class="listView">
          <li v-for="(item, index) in xitem.systemConfigTagListArr" :key="`${index}--listView`" @click="itemClick(item)" 
          v-show="getShow(item)">
            <div class="left">
              <img v-real-img="item.iconUrl" />
              <span>{{ item.tagNameShow }}</span>
            </div>
            <div class="right">
              <svg t="1658128888404" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                p-id="5184" width="0.31rem" height="0.31rem">
                <path
                  d="M750.976 532.288c7.68-19.456 3.84-42.688-11.456-58.496l0 0 0 0L358.4 79.808c-20.416-21.12-53.44-21.12-73.856 0-20.416 21.12-20.416 55.232 0 76.352L628.672 512l-344.192 355.84c-20.416 21.12-20.416 55.296 0 76.352 20.416 21.056 53.44 21.056 73.856 0l381.056-393.984C744.576 544.896 748.416 538.816 750.976 532.288z"
                  p-id="5185" fill="#788597"></path>
              </svg>
            </div>
          </li>
        </ul>
      </div>
    </div>
</van-pull-refresh>
</template>

<script>
import utils from "@/utils/index";
import { getBalance, backAllCoin, vipInfo, getUnreadNum } from "@/api/index";
import { mapState } from 'vuex';
export default {
  data() {
    return {
      actvieType: "language",
      itemList: [
        {
          src: require("../../assets/img/my/recharge.png"),
          font: this.$t('my.menuList.recharge'),
          link: "/mine/finance/myDeposit",
          code: "recharge"
        },
        {
          src: require("../../assets/img/my/withdraw.png"),
          font: this.$t('my.menuList.withdraw'),
          link: "/mine/finance/withdraw",
          code: "withdraw"
        },
        {
          src: require("../../assets/img/my/shop_ic.png"),
          font: this.$t('my.menuList.mall'),
          link: "/mine/mall",
          code: "buy"
        },
        {
          src: require("../../assets/img/my/noble_ic.png"),
          font: this.$t('my.menuList.noble'),
          link: "/mine/noble",
          code: "vipLevel"
        },
        {
          src: require("../../assets/img/my/icon/zhye.png"),
          font: this.$t('my.accountBalance'),
          link: "/mine/wallet",
          code: "balance"
        },
        {
          src: require("../../assets/img/my/icon/dljm.png"),
          font: this.$t('my.listView.jionProxy'),
          link: "/mine/proxy",
          code: "proxy"
        },
        {
          src: require("../../assets/img/my/icon/wddl.png"),
          font: this.$t('my.listView.myProxy'),
          link: "/mine/myProxy",
          code: "proxy"
        },
        {
          src: require("../../assets/img/my/icon/jyjl.png"),
          font: this.$t('my.listView.transaciton'),
          link: "/record/transaciton",
          code: "assetRecord"
        },
        {
          src: require("../../assets/img/my/icon/yxbg.png"),
          font: this.$t('my.listView.gameReport'),
          link: "/record/gameReport",
          code: "gameReport"
        },
        {
          src: require("../../assets/img/my/icon/wdgz.png"),
          font: this.$t('my.listView.myNoble'),
          link: "/mine/myNoble",
          code: "myVip"
        },
        {
          src: require("../../assets/img/my/icon/wddj.png"),
          font: this.$t('my.listView.myProps'),
          link: "/mine/myMall",
          code: "prop"
        },
        {
          src: require("../../assets/img/my/icon/gy.png"),
          font: this.$t('my.listView.about'),
          link: "/mine/about",
          code: "about"
        },
        {
          src: require("../../assets/img/my/icon/xtsj.png"),
          font: this.$t('my.listView.set'),
          link: "/mine/set",
          code: "systemConfig"
        },
        {
          code: "follow",
          link: "/mine/follow",
          key: "follows"
        },
        {
          code: "fans",
          link: "/mine/follow?type=fans",
          key: "fans"
        }
      ],
      balance: '0',
      isLoad: false,
      languageArr: [], //语言列表
      languageActive: 0,
      currencyArr: [], //货币列表
      currencyActive: 0,
      vipDetail: {}, //贵族信息
      scrollTop: 0,
      isRefreshDisable: false
    }
  },
  computed: {
    // 'letterList', 'systemLetterLists', 
    ...mapState(['member', 'systemConfig', 'isLoadMember', 'baseInfo', 'showDownApp', 'isUnreadMsgFlag']),
    isLogin() {
      return this.$store.getters.isLogin
    },
    tabList() {
      let arr = [];
      if (this.systemConfig && this.systemConfig.length > 0) {
        let index = this.systemConfig.findIndex(item => {
          return item.code == 'my';
        });
        
        if (index != -1 && this.systemConfig[index] && this.systemConfig[index].systemConfigTagList) {
          arr = this.systemConfig[index].systemConfigTagList; 

          arr.forEach(item => {
            if (item.systemConfigTagList) {
              item.systemConfigTagListArr = this.getData(item.systemConfigTagList);
            }
          })
        }
      }

      return arr;
    },
    // isUnreadMsg() { //查接口 跟推送结合
    //   let flag = false;
    //   this.letterList.forEach(item => {
    //     if (item.isUnreadMsg === 0) {
    //       flag = true;
    //     }
    //   })

    //   this.systemLetterLists.forEach(item => {
    //     if (item.isUnreadMsg === 0) {
    //       flag = true;
    //     }
    //   })

    //   return flag;
    // },
    hasVip() {
      let flag = false;
      if (this.member && this.member.badgeList.length > 0) {
        let vip = this.member.badgeList.filter(function(item){
          return [6,7,8,9,10].indexOf(item) != -1
        });

        if (vip.length > 0) {
          flag = true;
        }
      }

      return flag;
    },
    myBoxHeight() {
      let windowsVH = window.innerHeight;
      let height = `calc(${windowsVH}px - 1.06rem)`
      if (this.showDownApp) {
        height  = `calc(${windowsVH}px - 2.06rem)`
      }
      return height;
    }
  },
  watch: {
    scrollTop(newval) {
      if (newval > 0) {
        this.isRefreshDisable = true
      } else {
        this.isRefreshDisable = false
      }
    }
  },
  created() {
    this.$store.commit("setState", { entry: this.$route.path });
    if (sessionStorage.getItem('token')) {
      this.$store.dispatch('refreshUserInfo');
    }
    this.getUnreadNum();
  },
  mounted() {
    this.safariHacks();
    if (this.member && this.member.badgeList.length > 0) {
      let vip = this.member.badgeList.filter(function(item){
        return [6,7,8,9,10].indexOf(item) != -1
      });

      if (vip.length > 0) {
        this.vipInfo();
      }
    }

    // 解决滚动条与下拉的冲突
    this.$nextTick(() => {
      const vantList = document.querySelector('.my')
      if (vantList) {
        this.resolveConflict(vantList)
      }
    })
  },
  methods: {
    getUnreadNum() {
      getUnreadNum().then(res => {
          if (res.code === 0 || res.code === '0') {
            if (res.data.totalUnreadNum > 0) {
              this.$store.commit("setState", { isUnreadMsgFlag: true });
            } else {
              this.$store.commit("setState", { isUnreadMsgFlag: false });
            }
          } else {
            if (res.msg) this.$toast(res.msg);
          }
      }).catch(() => { })
    },
    /**
     * @description: 下拉刷新和滚动事件冲突
     * @param {*}
     * @return {*}
     */
     resolveConflict(val) {
      const vantList = val
      vantList.addEventListener('touchmove', () => {
        this.scrollTop = vantList.scrollTop
      })
      vantList.addEventListener('touchend', () => {
        this.scrollTop = vantList.scrollTop
      })
    },
    onRefresh() {
      if (sessionStorage.getItem('token')) {
        this.$store.dispatch('refreshUserInfo');
      }
    },
    // 是否展示
    getShow(item){
      let flag = false;
      if (item.status && item.code != 'myVip') {
        flag = true;
      }

      if (item.status && item.code == 'myVip' && this.hasVip) {
        flag = true;
      }

      return flag;
    },
    // 匹配用户等级图片
    getImg(val) {
      if (val <= 10) {
        return require("../../assets/img/noble/level/level_1-10.png");
      }

      if (10 < val && val <= 20) {
        return require("../../assets/img/noble/level/level_11-20.png");
      }

      if (20 < val && val <= 30) {
          return require("../../assets/img/noble/level/level_21-30.png");
      }

      if (30 < val && val <= 40) {
        return require("../../assets/img/noble/level/level_31-40.png");
      }

      if (40 < val && val <= 50) {
        return require("../../assets/img/noble/level/level_41-50.png");
      }

      if (50 < val && val <= 60) {
        return require("../../assets/img/noble/level/level_51-60.png");
      }

      if (60 < val && val <= 70) {
        return require("../../assets/img/noble/level/level_61-70.png");
      }

      if (70 < val && val <= 80) {
        return require("../../assets/img/noble/level/level_71-80.png");
      }

      if (80 < val && val <= 90) {
        return require("../../assets/img/noble/level/level_81-90.png");
      }

      if (90 < val) {
        return require("../../assets/img/noble/level/level_91-105.png");
      }
    },
    // 获取用户贵族信息
    vipInfo() {
      let params = {
        uid: this.member.uid
      }
      vipInfo(params).then(res => {
        if (res.code === 0 || res.code === '0') {
          this.vipDetail = res.data;
          sessionStorage.setItem('vipInfo', JSON.stringify(this.vipDetail));
        }
      }).catch(()=> {}) 
    },
    // 获取单独 开关
    getStauts(arr, key) {
      let flag = false;
      if (arr && arr.length > 0) {
        let index = arr.findIndex(item => {
          return item.code == key;
        });

        if (index != -1) {
          flag = arr[index].status;
        }
      }
      return flag;
    },
    // 匹配数据
    getData(arr) {
      let menuList = JSON.parse(JSON.stringify(this.itemList)), newList = [];
      let oldArr = JSON.parse(JSON.stringify(arr));

      oldArr.forEach((item) => {
        let index = menuList.findIndex(mItem => {
          return mItem.code == item.code;
        }),
        proxyArr = menuList.filter(fItem => {
          return fItem.code == item.code;
        });

        if (index != -1) {
          if (proxyArr.length == 1) {
            let obj = {...menuList[index], ...item};
            newList.push(obj);
          } else {
            let proxyIndex = -1;
            if (item.code == 'proxy' && this.member.proxyStatus == 1) {
              proxyIndex = proxyArr.findIndex(Pitem => {
                return Pitem.link == '/mine/myProxy'
              });
            }

            if (item.code == 'proxy' && this.member.proxyStatus != 1) {
              proxyIndex = proxyArr.findIndex(Pitem => {
                return Pitem.link == '/mine/proxy'
              });
            }
            let obj = {...proxyArr[proxyIndex], ...item};
            newList.push(obj);
          }
        }
      });

      newList = newList.sort(function (a, b) {
        return (a.tagSort - b.tagSort);
      });
      return newList;
    },
    // 获取对应展示权限
    getPermissions(item, key) {
      let flag = false;
      if (item.systemConfigTagList && item.systemConfigTagList.length > 0) {
        let index = item.systemConfigTagList.findIndex(item => {
          return item.code == key;
        });

        if (index != -1 && item.systemConfigTagList[index].status) {
          flag = true;
        }
      }
      return flag;
    },
    //获取对应展示文字 
    getString(item, key) {
      let string = '';
      if (item.systemConfigTagList && item.systemConfigTagList.length > 0) {
        let index = item.systemConfigTagList.findIndex(item => {
          return item.code == key;
        });

        if (index != -1) {
          string = item.systemConfigTagList[index].tagNameShow;
        }
      }
      return string;
    },
    // 余额跳转
    goWallet(item) {
      if (this.getPermissions(item, 'balanceLink')) {
        this.$router.push('/mine/wallet');
      }
    },
    goBackMy() {
      location.reload();
    },
    // 查询余额
    getuserBalance(bol) {
      if (this.isLoad) {
        return;
      }
      this.isLoad = true;
      getBalance().then(res => {
        if (res.code === 0 || res.code === '0') {
          this.balance = res.data ? utils.keepTwoDecimalFull(res.data) : '0';
          let obj = JSON.parse(JSON.stringify(this.member));
          obj.goldCoin = this.balance;
          this.$store.commit("setState", { member: obj });
          if (bol) {
            this.$toast(this.$t('common.refreshSuccess'));
          }
        } else {
          if (res.msg) this.$toast(res.msg);
        }
        this.isLoad = false;
      }).catch(() => {
        this.isLoad = false;
      });
    },
    // 一键回收
    recycle() {
      if (this.isLoad) {
        return;
      }
      let params = {
        uid: this.member.uid
      }
      this.isLoad = true;
      this.$store.commit("setState", { isLoadForLoad: true });
      backAllCoin(params).then(res => {
        if (res.code === 0 || res.code === '0') {
          this.getuserBalance();
        } else {
          if (res.msg) this.$toast(res.msg);
        }
        this.isLoad = false;
        this.$store.commit("setState", { isLoadForLoad: false });
      }).catch(err => {
        this.isLoad = false;
        this.$store.commit("setState", { isLoadForLoad: false });
        console.log(err);
      });
    },
    copy(value) {
      if (utils.copy(value)) {
        this.$toast(this.$t('common.copySuccess'));
      } else {
        this.$toast(this.$t('common.copyLost'));
      }
    },
    safariHacks() {
      let windowsVH = window.innerHeight;
      document.querySelector('.my').style.height = `calc(${windowsVH}px - 1.06rem)`;
    },
    goOtherPage(path) {
      // if (!path) return;
      // if (path != '/mine/helpCenter' && path != "/mine/about" && !this.isLogin) {
      //   this.$router.push("/login")
      // } else {
      //   this.$router.push(path);
      // };
      this.$router.push(path);
    },
    itemClick(item) {
      if (item.link && item.code != 'about') {
        this.goOtherPage(item.link);
      } else {
        if (item.code == 'about') {
          if (this.baseInfo && this.baseInfo.floorUrlWeb) {
            let url = this.baseInfo.floorUrlWeb
            if (sessionStorage.getItem('puid')) {
              url = url + '?puid=' + sessionStorage.getItem('puid')
            }
            window.location.href = url;
          }
        } else {
          this.inDevelop();
        }
      }
    },
    inDevelop() {
      this.$toast(this.$t('common.inDevelop'));
    }
  }
}
</script>
<style lang="scss" scoped>
.my {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: calc(100vh - 1.06rem);
  background-color: #f9f9f9;

  .header {
    font-size: .32rem;
    text-align: center;
    height: 0.88rem;
    line-height: .88rem;
    background-color: #f9f9f9;
    color: #414655;
    position: relative;

    .headR {
      position: absolute;
      right: 0;
      width: 33.3%;
      height: 0.88rem;
      vertical-align: middle;
      top: 0px;
      text-align: right;

      .notice {
        display: inline-block;
        position: relative;
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.2rem;

        span {
          display: inline-block;
          width: 0.15rem;
          height: 0.15rem;
          background-color: #C41F1A;
          position: absolute;
          top: 0.25rem;
          left: 0;
          border-radius: 50%;
        }
      }

      img {
        width: 0.4rem;
        height: 0.4rem;
        vertical-align: middle;
        margin-right: 0.28rem;
      }
    }
  }

  .myTop {
    font-size: 0.24rem;
    color: #969696;
    .avatarBox {
      width: 1.44rem;
      height: 1.44rem;
      margin: 0 auto;
      position: relative;

      .avatar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }

      .edit {
        position: absolute;
        bottom: 0;
        right: 0.4rem;
        width: 0.32rem;
        height: 0.32rem;
        z-index: 6;
      }
    }

    .userIdBox {
      font-size: 0.28rem;
      color: #666;
      margin-top: 0.2rem;

      .copy {
        background-color: #f74995;
        color: #f9f9f9;
        padding: 0.02rem 0.1rem;
        border-radius: 0.1rem;
        font-size: 0.2rem;
      }

        &>img {
          width: 0.36rem;
          margin-left: 0.1rem;
        }

      .level {
        position: relative;
        height: 0.35rem;
        margin-left: 0.1rem;

        img {
          width: 0.7rem;
          height: 0.34rem;
        }

        span {
          position: absolute;
          right: 0.05rem;
          color: #fff;
          line-height: 0.35rem;
          font-size: 0.2rem;

          &.small {
              right: 0.12rem;
          }
        }
      }

      .userIcon img {
        width: 0.36rem;
        margin-left: 0.1rem;
      }
    }
  }

  .interactive {
    margin-top: 0.36rem;
    color: #969696;
    font-size: 0.24rem;

    p:nth-child(1) {
      font-size: 0.4rem;
      color: #333;
    }
  }

  .centerWallet {
    font-size: 0.24rem;
    color: #f9f9f9;
    background: -webkit-linear-gradient(left,#1a2030,#3a405c);
    background: linear-gradient(90deg,#1a2030,#3a405c);
    padding: 0.2rem 0.2rem 0.12rem;
    margin: 0.4rem auto;
    width: calc(100% - 0.56rem);
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    position: relative;
    text-align: left;

    .total {
      color: #f5d6b7;
      font-size: 0.36rem;
      margin-bottom: 0.2rem;
    }

    .recycle {
      height: 0.56rem;
      line-height: 0.56rem;
      padding: 0 0.28rem;
      border-radius: 0.4rem;
      background-color: #f5d6b7;
      color: #333;
      position: absolute;
      right: 0.28rem;
      z-index: 2;
    }
  }

  // .vip {
  //   position: relative;
  //   width: 6.94rem;
  //   margin: 0.3rem 0.28rem 0;
  //   border-radius: 0.2rem;
  //   box-shadow: 0 0 0.16rem 0 rgb(0 0 0 / 10%);
  //   z-index: 3;
  //   background-size: cover;
  //   display: -webkit-flex;
  //   display: flex;
  //   -webkit-align-items: center;
  //   align-items: center;
  //   text-align: left;

  //   .itemleft {
  //     font-size: 0.24rem;
  //     width: 50%;
  //     padding: 0.4rem 0 0.52rem 0.4rem;
  //     box-sizing: border-box;
  //     color: #333;

  //     p {
  //       vertical-align: middle;
  //     }

  //     .refresh {
  //       width: 0.28rem;
  //       margin-left: 0.1rem;
  //       vertical-align: middle;
  //     }

  //     .money {
  //       margin-top: 0.16rem;
  //       font-size: 0.4rem;

  //       img {
  //         width: 0.4rem;
  //         margin-right: 0.1rem;
  //       }
  //     }
  //   }

  //   .item {
  //     display: flex;
  //     position: relative;
  //     width: 50%;
  //     z-index: 3;
  //     font-size: 0.32rem;

  //     .suItem {
  //       flex: 1;
  //       text-align: center;

  //       img {
  //         width: 0.64rem;
  //         height: 0.6rem;
  //       }

  //       p {
  //         color: #414655;
  //         font-size: .24rem;
  //         font-weight: 400;
  //         white-space: normal;
  //         line-height: .32rem;
  //         height: 0.32rem;
  //       }
  //     }
  //   }
  // }

  .menuBox {
    background-color: #F9F9F9;
    padding-bottom: 0.16rem;

    ul {
      display: -webkit-flex;
      display: flex;
      padding: 0.2rem 0.28rem 0.3rem;
      background-color: #fff;
      border-radius: 0.2rem;
      width: calc(100% - 0.56rem);
      margin: 0 auto;

      li {
        flex: 1;
        text-align: center;

        p {
          font-size: .24rem;
          font-weight: 500;
          color: #333;
          margin-top: -0.12rem;
          padding: 0 0.05rem;
        }

        img {
          width: 0.72rem;
          height: 0.72rem;
        }
      }
    }
  }

  .inviteBg {
    max-width: calc(100% - 0.56rem);
    height: 1.68rem;
    width: 100%;
    border-radius: 1rem;
    margin: 0 0.28rem;
  }

  .listView {
    li {
      text-align: center;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      padding-right: 0.28rem;
      background-color: #fff;
      height: 1rem;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0.28rem;
        bottom: 0;
        border-bottom: 1px solid #eee;
        -webkit-transform: scaleY(.5);
        transform: scaleY(.5);
      }

      &:last-child::after {
        border-bottom: 1px solid #fff;
      }

      .left {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        margin-left: 0.28rem;

        img {
          width: 0.44rem;
        }

        span {
          font-size: .28rem;
          color: #414655;
          margin-left: 0.2rem;
        }
      }

      .right {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;

        img {
          width: 0.25rem;
          height: 0.25rem;
        }
      }
    }
  }

  .currencyLanguage {
    background-color: #fff;

    .cardsTab {
      padding: 0.28rem;
      background: #fff;

      ul {
        -webkit-flex-flow: row nowrap;
        flex-flow: row nowrap;
        background: #EBEFF1;
        border-radius: 0.16rem;
        padding: 0.02rem;

        li {
          flex: 1 1;
          text-align: center;
          line-height: 0.58rem;
          height: 0.58rem;
          color: #bbb;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 400;
          font-size: 0.27rem;
          margin: 0.02rem;

          &.active {
            background: #fff;
            border-radius: 0.16rem;
            color: #333333;
          }
        }
      }
    }

    .bankListCont {
        margin-bottom: 0.3rem;

        .bankListCont_item {
            padding: 0.35rem 0;
            margin: 0 0.28rem;
            border-bottom: 1px solid #EBEFF1;

            .bankListCont_item_left {
              text-align: left;
              color: #333;
              font-size: 0.28rem;
            }

            img {
              width: 0.4rem;
            }
        }
    }
  }
}
</style>